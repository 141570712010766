import React from 'react'
import dynamic from 'next/dynamic'
import { Post } from '../../types/post'

const PostCard = dynamic(() => import('../postCard'))

type PostListProps = {
  posts: Post[]
}

const PostList: React.FC<PostListProps> = ({ posts }) => {
  return (
    <>
      {posts.map(post => (
        <PostCard key={post.id} post={post} />
      ))}
    </>
  )
}

export default PostList
